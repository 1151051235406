<template>
  <button
    class="ui-flex mx-[2px] py-[8px] px-[16px] w-[40px] h-[40px] ui-rounded-sm ui-justify-center ui-items-center ui-font-semibold"
    :class="[stateVariant[state]]"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import type { PaginationLinkProps } from "./types";

withDefaults(defineProps<PaginationLinkProps>(), {
  state: "idle",
});

const stateVariant = {
  ["idle"]: "hover:ui-bg-grey-light",
  ["active"]: "ui-bg-mkm-blue-light ui-text-white",
  ["disabled"]: "ui-bg-mkm-white ui-opacity-20 pointer-events-none",
};
</script>
